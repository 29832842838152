<template>
	<div>
		<div class="p-2">
			<div class="card">
				<div class="card-header justify-content-between">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input @keyup="applySearch" v-model.trim="search" class="form-control list-search" type="search"
							placeholder="Search" />
						<span class="input-group-text border-0">
							<i class="fe fe-search"></i>
						</span>
					</div>
					<div class="col-auto">
						<v-datepicker style="width: 100%" v-model="datePicker" placeholder="Filter by date" range></v-datepicker>
					</div>
				</div>
				<b-table striped hover selectable responsive show-empty :items="payouts" :fields="fields"
					 :busy="fetchEarnings" @row-clicked="onRowClicked">
					<template #table-busy>
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(serial)="data">
						<!-- {{ data.index + 1 }} -->
						{{ data.index + 1 + ((currentPage-1) * pageSize) }}
					</template>
					<template #cell(name)="data">
						<p>
							{{ `${data.item.owner?.fname} ${data.item.owner?.lname}` }}
						</p>
					</template>
					<template #cell(payoutDate)="data">
						{{ formatDate(data.item.referenceTime) }}
					</template>
					<template #cell(approvalsCount)="data">
						{{ data.item.approvalsCount }}/2
					</template>
					<template #cell(action)="data">
						<div>
							<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret no-flip
								class="m-2">
								<template #button-content>
									<p class="mt-3" style="font-weight: 900">&#x2026;</p>
								</template>
								<b-dropdown-item v-if="userHasPermission('partners009')" class="font-weight-bold" href="#"
									@click="markRevenueAsPaid(data, 'pending')">Mark as Paid
								</b-dropdown-item>
								<b-dropdown-item v-if="userHasPermission('partners009')" class="font-weight-bold" href="#"
									@click="openDeductionModal(data, 'pending')">
									Deduct from earnings
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>
				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
						</div>
						<div class="col-auto">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script setup>
	import { userHasPermission } from '@/composables/core/permissions'
</script>
  
<script>
	import PageHeader from '@/components/layout/PageHeader'
	import TableView from '@/mixins/tableview'
	import moment from 'moment'
	import {addToQuery, removeQueryParam} from '@/composables/utils'
	import router from '@/router'

	export default {
		name: 'Payout',
		components: {
			PageHeader,
		},
		mixins: [TableView],
		inject: ['openDeductionModal', 'markRevenueAsPaid', 'to_refresh'],
		data: () => {
			return {
				search: null,
				datePicker: [],
				currentPage: 1,
				pageSize: 20,
				totalRecords: 0,
				fetchEarnings: true,
				payouts: [],
				fields: [
					{
						key: 'serial',
						label: 'S/N',
					},
					{
						key: 'name',
						label: 'PARTNER NAME',
					},
					{
						key: 'payoutDate',
						label: 'PAYOUT MONTH',
					},

					{
						key: 'company_name',
						label: 'COMPANY NAME',
					},
					{
						key: 'company_email',
						label: 'EMAIL',
					},
					{
						key: 'amount',
						label: 'AMOUNT (₦)',
					},
					{
						key: 'approvalsCount',
						label: 'APPROVAL',
					},
					{
						key: 'action',
						label: 'ACTION',
					},
				],
				// route: useRoute()
			}
		},
		watch: {
			search() {
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					if(this.search) {
						addToQuery(this.$route, router, {search: this.search})
					} else {
						removeQueryParam(this.$route, router, ['search'])
					}
					this.getAccuredEarnings(true)
				}, 600)
			},
			dateFilterValid() {
				if(this.dateRange[0] && this.dateRange[1]) {
					addToQuery(this.$route, router, {from: this.dateRange[0], to: this.dateRange[1]})
				} else {
					removeQueryParam(this.$route, router, ['from','to'])
				}
				this.getAccuredEarnings(true)
			},
			currentPage() {
				this.getAccuredEarnings()
			},
			to_refresh(newValue, oldValue) {
				if(oldValue == 'pending' && newValue == '') this.getAccuredEarnings()
			}
		},
		computed: {
			filteredPayout() {
				const search = this.search.toLowerCase()
				return this.payouts.filter((payout) => {
					return (
						payout.owner.fname.toLowerCase().includes(search) ||
						payout.owner.lname.toLowerCase().includes(search) ||
						payout.company_email.toLowerCase().includes(search) ||
						payout.company_name.toLowerCase().includes(search)
					)
				})
			},
			dateRange() {
				if (!this.datePicker || !this.datePicker[0]) return [null, null]
				return this.datePicker.map((date) => moment(date).format('YYYY-MM-DD'))
			},
			dateFilterValid() {
				return (
					this.datePicker.length &&
					this.datePicker.filter((date) => !!date).length > 0
				)
			},
		},
		methods: {
			onRowClicked(data) {
				this.$router.push(`/partners/payout/${data.partnerNumber}/${data.id}`)
			},
			formatDate(inputString) {
				if (!inputString) return 'N/A'
				const date = new Date(inputString)
				const options = { year: 'numeric', month: 'long' }
				const year = date.toLocaleDateString('en-US', options)
				return year
			},
			applySearch() { },
			async getAccuredEarnings(reset = false) {
				if (reset) {
					this.currentPage = 1
				}
				this.fetchEarnings = true
				try {
					const res = await this.axios.get(
						`cost-revenue/v1/earnings?status=pending-payout,pending-settlement&page=${this.currentPage}&perPage=${this.pageSize}${this.search ? `&search=${this.search}` : ''}${this.dateRange[0] ? `&startDate=${this.dateRange[0]}` : ''}${this.dateRange[1] ? `&endDate=${this.dateRange[1]}` : ''}`
					)
					if (res.status == 200) {
						this.payouts = res.data.result
						this.totalRecords = res.data.metadata.total
					}
				} catch (err) {
					if (err.response.status == 500) {
						this.$toastr.e('Something went wrong', 'Error')
					} else {
						this.$toastr.e('Please check with Admin', 'Error')
					}
				} finally {
					this.fetchEarnings = false
				}
			},
			checkForFilters() {
				const q = this.$route.query
				if (q.search) this.search = q.search
				if(q.from && q.to) this.datePicker = [new Date(q.from), new Date(q.to)]
				this.getAccuredEarnings()
			}
		},
		mounted() {
			this.checkForFilters()
		},
	}
</script>
  
<style lang="scss" scoped>
	@import '@/assets/scss/partials/_sh-colors.scss';

	.config-btn-cancel {
		display: none;
		color: #00000d;
		background-color: transparent;
		border: 1px solid $sh-gray-9;
	}
</style>
  